<template>
    <div 
        v-if="!seenCookie && showBanner"
        class="fair-future-banner"
    >
        <div class="fair-future-banner__inner">
            <img 
                class="fair-future-banner__image fair-future-banner__image--desktop" 
                src="public/img/fair-future-campaign-banner--desktop.jpg"
                alt="Fair Future Challenge - Win €100000 voor jouw duurzame product of dienst"
            >
            <img 
                class="fair-future-banner__image fair-future-banner__image--mobile"
                src="public/img/fair-future-campaign-banner--mobile.jpg"
                alt="Fair Future Challenge - Win €100000 voor jouw duurzame product of dienst"
            >
            <a
                href="https://fairfuturechallenge.nl"
                class="button button--primary fair-future-banner__button"
            >
                Meld je aan
                <BaseIcon icon="chevron-right" />
                <span class="sr-only">voor 3 december 2024</span>
            </a>
            <button 
                class="fair-future-banner__close"
                @click="handleClose"
            >
                <BaseIcon icon="cross" />
            </button>
        </div>
        <div 
            class="fair-future-banner__backdrop"
            @click="handleClose"
        />
    </div>
</template>

<script setup>
const showBanner = ref(false);

const bannersDismissed = useCookie(
    'bannersDismissed',
    {
        default() { return []; },
        maxAge: 60 * 60 * 24 * 365,
    }
);

const seenCookie = computed(() => bannersDismissed.value?.includes('fair-future-challenge'));

const handleClose = () => {
    bannersDismissed.value.push('fair-future-challenge');
};

onMounted(() => {
    setTimeout(() => {
        showBanner.value = true;
    }, 2000);
});
</script>

<style lang="less" src="./FairFutureBanner.less" scoped />
